import React from "react";
import useSWR, { mutate } from "swr";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import AccountInfoesForm from "./AccountInfoesForm";
import gpib from "../../../apis/gpib";
import Modal from "../../Modal";
import Loader from "../../Loader";
import ErrorMessage from "../../ErrorMessage";

const parseSubmitValues = v => ({
  id: v.id,
  userID: v.userID,
  firstName: v.firstName,
  middleName: v.middleName || undefined,
  lastName: v.lastName,
  signUpDate: v.signUpDate,
  randomCent: parseInt(v.randomCent),
  idVerificationStatus: parseInt(v.idVerificationStatus),
  yob: parseInt(v.yob),
  bankID: parseInt(v.bankID),
  fees: Number(v.fees),
  referredBy: v.referredBy
});

const parseInitialValues = fetchedData =>
  [
    "id",
    "userID",
    "firstName",
    "middleName",
    "lastName",
    "signUpDate",
    "randomCent",
    "idVerificationStatus",
    "yob",
    "bankID",
    "fees",
    "referredBy"
  ].reduce((map, item) => {
    if (item in fetchedData) map[item] = fetchedData[item];
    return map;
  }, {});

const InfoesModalForm = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const url = id && `/user/${id}`;
  const submitUrl = `/accountinfoes/user/${id}`;

  const { data, error, isValidating } = useSWR(url, {
    revalidateOnFocus: false
  });

  const initialValues = data && parseInitialValues(data);

  const onDismiss = () => {
    const path = location.pathname.replace(/\/accountinfoes\/.*$/, "");
    navigate(path);
  };

  const onSubmit = async (v, formActions, modalActions) => {
    try {
      const values = parseSubmitValues(v);
      await gpib.secure.put(submitUrl, values);
      if (v.referredBy) {
        await gpib.secure.post("/Referral/add", {
          userID: v.userID,
          friendUserID: v.referredBy
        });
      }
      mutate(`/user/${id}`, ac => ({ ...ac, ...v }));
      modalActions.onDismiss();
    } catch (e) {
      formActions.setErrors({ hidden: e });
      formActions.setSubmitting(false);
    }
  };

  return (
    <Modal isOpen onDismiss={onDismiss} heading={"Edit User Details"}>
      {({ onDismiss, wrapCallback }) => (
        <>
          <Loader loading={isValidating} diameter="2rem" />
          <ErrorMessage error={error} />
          {!error ? (
            <AccountInfoesForm
              onDismiss={onDismiss}
              onSubmit={wrapCallback(onSubmit)}
              initialValues={initialValues}
              submitText="Save"
            />
          ) : (
            <Button
              block
              variant="secondary"
              children="Cancel"
              onClick={onDismiss}
            />
          )}
        </>
      )}
    </Modal>
  );
};

export default InfoesModalForm;
