import React from "react";
import { Formik, Form } from "formik";
import Input from "../form-inputs/Input";
import ErrorMessage from "../../ErrorMessage";
import SubmitSpinnerButton from "../SubmitSpinnerButton";

const SweepForm = ({
  initialValues: _inititalValues,
  onSubmit,
  submitText = "Sweep"
}) => {
  const initialValues = {
    userID: "",
    from: "",
    to: "",
    password: "",
    ..._inititalValues
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isSubmitting, errors }) => (
        <Form className="deposit-form">
          <Input
            label="User ID"
            name="userID"
            disabled={initialValues?.userID}
          />
          
          <Input label="From" name="from" disabled={initialValues?.from} />
          <Input name="fromID" hidden="true" />
          <Input label="From Address" name="from_address" disabled={initialValues?.from_address} />
          <Input label="To" name="to" />
          <Input label="To Address" name="to_address" disabled={initialValues?.to_address} />
          <Input label="Wallet Password" name="password" />
          <ErrorMessage error={errors.hidden} />
          <SubmitSpinnerButton
            submitText={submitText}
            isSubmitting={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

export default SweepForm;
