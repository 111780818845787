import React from "react";
import { Formik, Form } from "formik";
import { mutate } from "swr";
import gpib from "../../../apis/gpib";
import Input from "../form-inputs/Input";
import ToggleField from "../form-inputs/ToggleField";
import ErrorMessage from "../../ErrorMessage";
import SubmitSpinnerButton from "../SubmitSpinnerButton";

const defaultInitialValues = {
  password: "",
  amount: 0,
  fee: "",
  customMessage: "",
  buyOffExchange: false,
  useSegwit: true,
  selected: []
};

const validate = ({ fee }) => {
  let errors = {};
  if (!fee && String(fee) !== "0") errors.fee = "Fee is required";
  return errors;
};

const parseSubmitValues = v => ({
  password: v.password,
  customMessage: v.customMessage,
  minersFee: Number(v.fee),
  amount: Number(v.amount),
  buyOffExchange: v.buyOffExchange,
  useSegwit: v.useSegwit,
  toTransfer: v.selected.map(id => Number(id))
});

const PendingTransferForm = ({ initialValues: iv, onSuccess, onError }) => {
  const initialValues = { ...defaultInitialValues, ...iv };
  const onSubmit = async (values, actions) => {
    try {
      const parsedValues = parseSubmitValues(values);

      console.log("parsedValues", parsedValues);

      if (parsedValues.buyOffExchange) {
        console.log(`buying ${parsedValues.amount} off exchange`);
        await gpib.secure.post(`/exchange/buy?amount=${parsedValues.amount}&withdraw=true`); 
      }

      await gpib.secure.post("/transfer", parsedValues);
      
      mutate("/transfer");
      mutate("/transfer/pending", state =>
        state.transfers.filter(
          t => !values.selected.find(id => id === String(t.id))
        )
      );
      
      actions.setSubmitting(false);
      if (onSuccess) onSuccess(values, actions);
    } catch (e) {
      actions.setErrors({ hidden: e });
      actions.setSubmitting(false);
      if (onError) onError(e);
    }
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validate}
      enableReinitialize
    >
      {({ isSubmitting, errors }) => (
        <Form className="pb-5">
          <ToggleField label="Buy Off Exchange" name="buyOffExchange" />
          <Input label="Custom Message" name="customMessage" />
          <Input label="Fee" name="fee" placeholder="fee" />
          <Input
            label="Password"
            name="password"
            type="password"
            placeholder="password"
          />
          <ErrorMessage error={errors.hidden} />
          <SubmitSpinnerButton
            submitText="Submit"
            isSubmitting={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

export default PendingTransferForm;
