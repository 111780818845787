import { isDecimal } from "validator";

const validate = values => {
  const requiredMsg = "This field is required";
  const errors = {};

  if (!String(values.fixedAmount)) errors.fixedAmount = requiredMsg;
  if (!String(values.percentageAmount)) errors.percentageAmount = requiredMsg;

  if (!isDecimal(String(values.fixedAmount)))
    errors.fixedAmount = "Fixed amount must be a valid currency amount";
  if (!isDecimal(String(values.percentageAmount)))
    errors.percentageAmount =
      "Percentage amount must be a valid currency amount";

  return errors;
};

export default validate;
