import React, { useContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { DepositModalForm } from "./forms/DepositForm";
import { TransferModalForm } from "./forms/TransferForm";
import AddressPage from "../pages/AddressPage";
import BillsPage from "../pages/BillsPage";
import DepositsPage from "../pages/DepositsPage";
import DepositsUploadPage from "../pages/DepositsUploadPage";
import HistoryPage from "../pages/HistoryPage";
import HomePage from "../pages/Home";
import LoginPage from "../pages/LoginPage";
import PendingTransfersPage from "../pages/PendingTransfersPage";
import TransfersPage from "../pages/TransfersPage";
import TransactionsPage from "../pages/TransactionsPage";
import ReferralPage from "../pages/ReferralPage";
import UserPage from "../pages/UserPage";
import UserDetailsPage from "../pages/UserDetailsPage";
import { AuthContext } from "./Auth";

import RefreshLoginModal from "./forms/LoginForm/RefreshLoginModal";
import AccountInfoesModalEdit from "./forms/AccountInfoesForm/AccountInfoesModalEdit";
import DepositHintsModalForm from "./forms/DepositHintsForm/DepositHintsModalForm";
import PendingTransferModalForm from "./forms/PendingTransferForm/PendingTransferModalForm";
import EnterprisesPage from "../pages/EnterprisesPage";
import EnterpriseModalEdit from "./forms/EnterprisesForm/EnterpriseModalEdit";
import RebuildDepositFormModal from "./forms/RebuildDepositForm/RebuildDepositFormModal";
import ReferralStrategyModal from "./forms/ReferralStrategyForm/ReferralStrategyModal";
import ReferralSettingModal from "./forms/ReferralStrategyForm/ReferralSettingModal";
import UnmatchedDeposit from "../pages/UnmatchedDepositPage";
import SweepModalForm from "./forms/Address/SweepModalForm";
import Loader from "./Loader";

const Router = () => {
  const { user, isLoading } = useContext(AuthContext);
  const authRedirectHandler = (targeElement) => (isLoading ? <Loader loading /> : user ? targeElement : <Navigate to={"/login"} />);

  return (
    <BrowserRouter>
      <RefreshLoginModal />
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/deposits/upload" element={authRedirectHandler(<DepositsUploadPage />)} />
        <Route path="/deposits/unmatched" element={authRedirectHandler(<UnmatchedDeposit />)} />
        <Route path="/deposits" element={authRedirectHandler(<DepositsPage />)} />
        <Route path="/pendingtransfers" element={authRedirectHandler(<PendingTransfersPage />)} />
        <Route path="/transfers" element={authRedirectHandler(<TransfersPage />)} />
        <Route path="/transactions" element={authRedirectHandler(<TransactionsPage />)} />
        <Route path="/addresses" element={authRedirectHandler(<AddressPage />)} />
        <Route path="/history" element={authRedirectHandler(<HistoryPage />)} />
        <Route path="/bills" element={authRedirectHandler(<BillsPage />)} />
        <Route path="/referral" element={authRedirectHandler(<ReferralPage />)} />
        <Route path="/users" element={authRedirectHandler(<UserPage />)} />
        <Route path="/user/:id" element={authRedirectHandler(<UserDetailsPage />)} />
        <Route path="/enterprises" element={authRedirectHandler(<EnterprisesPage />)} />
        <Route path="/" element={authRedirectHandler(<HomePage />)} />
        <Route path="/bills/edit/:id" element={authRedirectHandler(<TransferModalForm />)} />
        <Route path="/referral/strategy/add"
          element={authRedirectHandler(<ReferralStrategyModal />)}
        />
        <Route path="/referral/strategy/edit/:id"
          element={authRedirectHandler(<ReferralStrategyModal />)}
        />
        <Route path="/referral/setting/add"
          element={authRedirectHandler(<ReferralSettingModal />)}
        />
        <Route path="/referral/setting/edit/:id"
          element={authRedirectHandler(<ReferralSettingModal />)}
        />
        <Route path="/enterprises/add" element={authRedirectHandler(<EnterpriseModalEdit />)} />
        <Route path="/enterprises/edit/:id" element={authRedirectHandler(<EnterpriseModalEdit />)} />
        <Route path="/deposits/add" element={authRedirectHandler(<DepositModalForm />)} />
        <Route path="/deposits/edit/:id" element={authRedirectHandler(<DepositModalForm />)} />
        <Route path="/deposits/rebuild/:id"
          element={authRedirectHandler(<RebuildDepositFormModal />)}
        />
        <Route path="/transfers/edit/:id" element={authRedirectHandler(<TransferModalForm />)} />
        <Route path="/user/:id/accountinfoes/edit"
          element={authRedirectHandler(<AccountInfoesModalEdit />)}
        />
        <Route path="/user/:id/deposithints/edit"
          element={authRedirectHandler(<DepositHintsModalForm />)}
        />
          <Route path="/user/:id/address/sweep"
          element={authRedirectHandler(<SweepModalForm />)}
        />
        <Route path="/pendingtransfers/process/:selected"
          element={authRedirectHandler(<PendingTransferModalForm />)}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
