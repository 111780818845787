import React from "react";
import { Formik, Form } from "formik";
import Input from "../form-inputs/Input";
import TextArea from "../form-inputs/TextArea";
import ErrorMessage from "../../ErrorMessage";
import validate from "./validate";
import SubmitSpinnerButton from "../SubmitSpinnerButton";

const EnterprisesForm = ({ initialValues: _inititalValues, onSubmit }) => {
  const initialValues = {
    name: "",
    abn: "",
    emailDomain: "",
    contactEmail: "",
    contactPhoneNumber: "",
    payrollContact: "",
    payrollInformation: "",
    numberOfEmployees: "",
    notes: "",
    nameAbbreviation: "",
    ..._inititalValues
  };

  const formatABN = val => {
    return val
      .trim()
      .replace(/\s/gi, "")
      .slice(0, 11)
      .split("")
      .map((el, idx) => {
        if (idx === 2 || idx === 5 || idx === 8) return ` ${el}`;
        return el.toString();
      })
      .reduce((acc, el) => acc + el, "");
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      enableReinitialize
    >
      {({ isSubmitting, errors, setFieldValue }) => (
        <Form className="enterprise-form">
          <Input label="Business Name" name="name" />
          <Input label="Business Name Abbreviation" name="nameAbbreviation" />
          <Input
            label="ABN"
            name="abn"
            onKeyUp={e => {
              const val = e.target.value;
              e.target.value = formatABN(val);
              setFieldValue("abn", formatABN(val));
            }}
          />
          <Input label="Domain Name" name="emailDomain" />
          <Input label="Contact Email" name="contactEmail" />
          <Input label="Contact Phone Number" name="contactPhoneNumber" />
          <Input label="Contact Name" name="payrollContact" />
          <Input label="Payroll Information" name="payrollInformation" />
          <Input label="Number of Employees" name="numberOfEmployees" />
          <TextArea label="Notes" name="notes" />
          <ErrorMessage error={errors.hidden} />
          <SubmitSpinnerButton
            submitText="Submit"
            isSubmitting={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EnterprisesForm;
