import React from "react";
import useSWR, { mutate } from "swr";
import { useParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import SweepForm from "./SweepForm";
import gpib from "../../../apis/gpib";
import Modal from "../../Modal";
import Loader from "../../Loader";
import ErrorMessage from "../../ErrorMessage";

const parseSubmitValues = v => ({
  userID: v.userID,
  from: v.from,
  to: v.to,
  password: v.password
});

const parseInitialValues = fetchedData =>
  [
    "userID",
    "from",
    "to"
  ].reduce((map, item) => {
    if (item in fetchedData) map[item] = fetchedData[item];
    return map;
  }, {});

const SweepModalForm = () => {
  const { id } = useParams();
  const [searchParams, ] = useSearchParams();
  const from = searchParams.get("from");
  const from_address = searchParams.get("from_address");
  const to = searchParams.get("to");
  const to_address = searchParams.get("to_address");
  const location = useLocation();
  const navigate = useNavigate();
  const url = id && `/user/${id}`;
  const submitUrl = `/transfer/sweep`;

  const { data, error, isValidating } = useSWR(url, {
    revalidateOnFocus: false
  });

  // const initialValues = data && parseInitialValues(data);
  const initialValues = {
    userID: id,
    from,
    to,
    from_address,
    to_address,
    password: ""
  }

  const onDismiss = () => {
    const path = location.pathname.replace(/\/sweep\/.*$/, "");
    navigate(path);
  };

  const onSubmit = async (v, formActions, modalActions) => {
    try {
      const values = parseSubmitValues(v);
      await gpib.secure.post(submitUrl, values);

      mutate(`/user/${id}`, ac => ({ ...ac, ...v }));
      modalActions.onDismiss();
    } catch (e) {
      formActions.setErrors({ hidden: e });
      formActions.setSubmitting(false);
    }
  };

  return (
    <Modal isOpen onDismiss={onDismiss} heading={"Sweep"}>
      {({ onDismiss, wrapCallback }) => (
        <>
          <Loader loading={isValidating} diameter="2rem" />
          <ErrorMessage error={error} />
          {!error ? (
            <SweepForm
              onDismiss={onDismiss}
              onSubmit={wrapCallback(onSubmit)}
              initialValues={initialValues}
              submitText="Sweep"
            />
          ) : (
            <Button
              block
              variant="secondary"
              children="Cancel"
              onClick={onDismiss}
            />
          )}
        </>
      )}
    </Modal>
  );
};

export default SweepModalForm;
