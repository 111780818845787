import React, { useEffect, useState } from "react";
import useSWR, { mutate } from "swr";
import Layout from "../components/Layout";
import Loader from "../components/Loader";
import { Button } from "react-bootstrap";
import UnmatchesTable from "../components/tables/UnmatchesTable";
import useSelectedRows from "../hooks/useSelectedRows";
import Quote from "../components/Quote";
import Modal from "../components/Modal";
import MatchedDepositForm from "../components/forms/MatchedDepositsForm";
import gpib from "../apis/gpib";

const UnmatchedDeposit = () => {
  const { data: unmatchedDeposits, error: fetchUnmatchedDepositsError } =
    useSWR("/processcsv/unmatched");

  const isFetching = !unmatchedDeposits && !fetchUnmatchedDepositsError;
  const [selected, dispatch, selectRowConfig] = useSelectedRows();
  const [quote, setQuote] = useState(0);
  const [isMatchModalOpen, setMatchModalOpen] = useState(false);
  const [matchSubmitError, setMatchSubmitError] = useState(null);

  useEffect(() => {
    const filterSelected = selected.filter(
      id => unmatchedDeposits && unmatchedDeposits?.find(t => t.id === id)
    );
    if (selected.length !== filterSelected.length)
      dispatch({ type: "RESET", payload: filterSelected });
  }, [unmatchedDeposits, selected, dispatch]);

  const selectUnmatches = selected.map(id =>
    unmatchedDeposits.find(m => m.id === id)
  );

  const processMatches = async (values, actions) => {
    setMatchSubmitError(null);
    try {
      const reqBody = {
        rate: Number(values.rate),
        deposits: values.deposits.map(d => ({
          created: d.created,
          amount: Number(d.amount),
          reference: d.reference,
          bankID: Number(d.bankID),
          fee: Number(d.fee),
          bankTransactionDate: d.bankTransactionDate,
          userID: d.userID
        }))
      };
      await gpib.secure.post("/deposit", reqBody);
      const unmatchedReq = {
        ids: values.deposits.map(d => d.id)
      };
      await gpib.secure.post("/processcsv/unmatched", unmatchedReq);
      mutate("/processcsv/unmatched");
      dispatch({ type: "RESET" });
      setMatchModalOpen(false);
    } catch (error) {
      setMatchSubmitError(error);
      actions.setSubmitting(false);
    }
  };
  return (
    <Layout activeTab="Deposits">
      <div className="container-fluid py-5">
        <Loader loading={isFetching} />
        <div className="mb-3 d-flex justify-content-between">
          <h1>Unmatched Deposits</h1>
          <div>
            <Button
              disabled={!selected.length}
              onClick={() => setMatchModalOpen(true)}
            >
              Process Unmatched Deposits
            </Button>
          </div>
        </div>
        <Quote deposits={selectUnmatches} setQuote={setQuote} />
        <UnmatchesTable
          unmatches={unmatchedDeposits}
          selectRow={selectRowConfig}
        />
      </div>
      <Modal
        heading="Process Matched Deposits"
        isOpen={isMatchModalOpen}
        onDismiss={() => setMatchModalOpen(false)}
        large
      >
        <MatchedDepositForm
          onSubmit={processMatches}
          onDismiss={() => setMatchModalOpen(false)}
          initialValues={{ deposits: selectUnmatches, rate: quote || 0 }}
          submitError={matchSubmitError}
        />
      </Modal>
    </Layout>
  );
};

export default UnmatchedDeposit;
