import React from "react";
import { Formik, Form } from "formik";
import Input from "../form-inputs/Input";
import ErrorMessage from "../../ErrorMessage";
import validate from "./validate.js";
import SubmitSpinnerButton from "../SubmitSpinnerButton";

const DepositHintsForm = ({
  initialValues: _inititalValues,
  onSubmit,
  submitText = "Submit",
  isEditForm
}) => {
  const initialValues = {
    userID: "",
    employerName: "",
    depositReference: "",
    depositAmount: "",
    bankStatement: "",
    ..._inititalValues
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      enableReinitialize
    >
      {({ isSubmitting, errors }) => (
        <Form className="deposit-form">
          <Input label="User ID" name="userID" disabled={isEditForm} />
          <Input label="Employer Name" name="employerName" />
          <Input label="Admin Deposit Reference" name="depositReference" />
          <Input label="Deposit Reference" name="bankStatement" disabled />
          <Input label="Deposit Amount" name="depositAmount" />
          <ErrorMessage error={errors.hidden} />
          <SubmitSpinnerButton
            submitText={submitText}
            isSubmitting={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

export default DepositHintsForm;
