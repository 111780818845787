import React from "react";
import { Formik, Form, Field } from "formik";
import Loader from "../../Loader";
import Input from "../form-inputs/Input";
import ErrorMessage from "../../ErrorMessage";
import { Button } from "react-bootstrap";

const ReferralSettingForm = ({ initialValues, onSubmit }) => {
  const validate = values => {
    const requiredMsg = "This field is required";
    const errors = {};
    if (!String(values.referralCode)) errors.referralCode = requiredMsg;
    if (!String(values.userID)) errors.userID = requiredMsg;
    return errors;
  };
  return (
    <Formik
      initialValues={{
        userID: "",
        referralCode: "",
        enabled: false,
        ...initialValues
      }}
      validate={validate}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isSubmitting, errors }) => (
        <Form style={{ flex: "1" }}>
          <Input name="referralCode" label="Referral Code" />
          <Input name="userID" label="User ID" />
          <label>Enabled</label>
          <Field
            as="select"
            name="enabled"
            id="enabled"
            className="form-control"
          >
            <option value="true">True</option>
            <option value="false">False</option>
          </Field>
          <p></p>
          <ErrorMessage error={errors.hidden} />
          <Button
            className="btn btn-primary btn-block relative d-flex justify-content-center"
            type="submit"
            disabled={isSubmitting}
          >
            <Loader
              loading={isSubmitting}
              noBackground
              noStretch
              light
              diameter="1.4rem"
            />
            <span className="mx-2">Submit</span>
          </Button>
        </Form>
      )}
    </Formik>
  );
};
export default ReferralSettingForm;
