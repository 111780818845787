import React from "react";
import Modal from "../Modal";
import gpib from "../../apis/gpib";
import EmailForm from "../forms/EmailForm";

const SendEmailModal = ({ isOpen, onDismiss, onSuccess, initialValues }) => {
  const sendEmails = async (values, actions) => {
    try {
      const reqBody = values.to.map(user => ({
        userID: user.userID,
        email: user.email,
        cc: values.cc,
        bcc: values.bcc,
        subject: values.subject,
        body: values.body
      }));

      await gpib.secure.post("/email", reqBody);
      if (onSuccess) onSuccess(values);
      onDismiss();
    } catch (e) {
      actions.setErrors({ hidden: e });
      actions.setSubmitting(false);
    }
  };
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} heading="Email" large>
      <EmailForm onSubmit={sendEmails} initialValues={initialValues} />
    </Modal>
  );
};

export default SendEmailModal;
