import React from "react";
import { Formik, Form } from "formik";
import { mutate } from "swr";
import Input from "../form-inputs/Input";
import ErrorMessage from "../../ErrorMessage";
import Loader from "../../Loader";
import gpib from "../../../apis/gpib";
import validate from "./validate";

const defaultInitialValues = {
  rate: "0",
  selected: ""
};

const RebuildDepositForm = ({ initialValues: iv, onSuccess, onError }) => {
  const initialValues = { ...defaultInitialValues, ...iv };

  const parseSubmitValues = v => ({
    rate: Number(v.rate),
    depositIDs: [Number(v.selected)]
  });

  const onSubmit = async (values, actions) => {
    try {
      const parsedValues = parseSubmitValues(values);
      await gpib.secure.post("/Deposit/rebuild", parsedValues);
      mutate("/deposits");
      onSuccess();
    } catch (error) {
      actions.setErrors({ hidden: error });
      actions.setSubmitting(false);
      if (onError) onError(error);
    }
  };
  return (
    <div>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        enableReinitialize
      >
        {({ isSubmitting, errors }) => (
          <Form className="pb-5">
            <Input label="Exchange Rate" name="rate" />
            <ErrorMessage error={errors.hidden} />

            <button
              className="btn btn-primary btn-block relative d-flex justify-content-center"
              type="submit"
              disabled={isSubmitting}
            >
              <Loader
                loading={isSubmitting}
                noBackground
                noStretch
                light
                diameter="1.4rem"
              />
              <span className="mx-2">Submit</span>
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RebuildDepositForm;
