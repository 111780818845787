import React, { useMemo, useState } from "react";
import Layout from "../components/Layout";
import useSWR, { mutate } from "swr";
import { Button } from "react-bootstrap";
import ErrorMessage from "../components/ErrorMessage";
import Loader from "../components/Loader";
import EnterprisesTable from "../components/tables/EnterprisesTable";
import useSelectedRows from "../hooks/useSelectedRows";
import Modal from "../components/Modal";
import SubmitButtonSpinner from "../components/forms/SubmitSpinnerButton";
import gpib from "../apis/gpib";
import { useNavigate } from "react-router-dom";
import FileUpload from "../components/FileUpload";

const filterStatus = status => e => e.status === status;
const memoizeByStatus = (data, status) => () =>
  data && data.filter(filterStatus(status));
const mutateStatus = (selected, status) => state =>
  state.map(e => (selected.find(v => v === e.id) ? { ...e, status } : e));

const EnterprisesPage = () => {
  const navigate = useNavigate();
  const { data, error } = useSWR("/enterprise");
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalProps, setModalProps] = useState({});
  const toggleModal = () => setModalOpen(v => !v);
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const pending = useMemo(memoizeByStatus(data, 0), [data]);
  const approved = useMemo(memoizeByStatus(data, 1), [data]);
  const rejected = useMemo(memoizeByStatus(data, 2), [data]);
  const isLoading = !data && !error;
  const [uploadError, setUploadError] = useState(null);
  const [isUploading, setUploading] = useState(false);

  const [selectedPending, dispatchPending, selectPendingConfig] =
    useSelectedRows();

  const [selectedApproved, dispatchApproved, selectedApprovedConfig] =
    useSelectedRows();

  const processSelected = (action, status) => async () => {
    try {
      setSubmitting(true);
      await gpib.secure.post(`/enterprise/${action}`, selectedPending);
      mutate("/enterprise", mutateStatus(selectedPending, status));
      dispatchPending({ type: "RESET" });
      dispatchApproved({ type: "RESET" });
      setSubmitting(false);
      toggleModal();
    } catch (e) {
      setSubmitError(e);
      setSubmitting(false);
    }
  };

  const confirmRject = async () => {
    setModalProps({
      heading: "Reject Enterprise(s)",
      action: "Reject",
      onSubmit: processSelected("reject", 2)
    });
    toggleModal();
  };

  const confirmApprove = () => {
    setModalProps({
      heading: "Approve Enterprise(s)",
      action: "Approve",
      onSubmit: processSelected("approve", 1)
    });
    toggleModal();
  };

  const addEnterprise = () => {
    navigate("/enterprises/add");
  };

  const onFileUpload = async e => {
    const file = e.target.files[0];
    e.target.value = "";
    if (!file) return;
    if (Math.round(file.size / 1024) >= 2048) {
      return setUploadError("Please select a file less than 2mb");
    }
    if (!file.name.match(/\.(jpg|jpeg|png|webp)$/)) {
      return setUploadError(
        "Please select valid image type (jpg,jpeg,png,webp)"
      );
    }

    try {
      setUploading(true);
      setUploadError(null);
      const fd = new FormData();

      fd.append("logo", file, file.name);
      await gpib.secure.post(
        `/Enterprise/uploadLogo/${
          selectedPending.length === 0 ? selectedApproved : selectedPending
        }`,
        fd,
        {
          "Content-Type": "multipart/form-data",
          "Cache-Control": "no-store"
        }
      );
      mutate("/enterprise");
    } catch (error) {
      setUploadError(e.message);
    }
    setUploading(false);
  };

  return (
    <Layout activeTab="Enterprises">
      <div className="container-fluid py-5">
        <Loader loading={isLoading || isUploading} />
        <h1 className="mb-3">Enterprises</h1>
        <ErrorMessage error={error || uploadError} />
        <div className="mb-3 d-flex justify-content-between">
          <h3 className="my-3">Pending</h3>
          <div className="d-flex align-items-end">
            <Button className="me-2" children="Add" onClick={addEnterprise} />
            <Button
              className="me-2"
              children="Edit"
              onClick={() => {
                navigate(`/enterprises/edit/${selectedPending}`);
              }}
              disabled={selectedPending.length <= 0}
            />
            <Button
              className="me-2"
              children="Approve"
              onClick={confirmApprove}
              disabled={selectedPending.length <= 0}
            />
            <Button
              className="me-2"
              children="Reject"
              onClick={confirmRject}
              disabled={selectedPending.length <= 0}
            />
            <FileUpload
              children="Upload Logo"
              className="btn btn-primary me-2"
              onChange={onFileUpload}
              disabled={selectedPending.length <= 0}
            />
          </div>
        </div>
        <EnterprisesTable
          enterprises={pending}
          selectRow={selectPendingConfig}
        />
        <div className="mb-3 d-flex justify-content-between">
          <h3 className="my-3">Approved</h3>
          <div className="d-flex align-items-end">
            <Button
              className="me-2"
              children="Edit"
              onClick={() => {
                navigate(`/enterprises/edit/${selectedApproved}`);
              }}
              disabled={selectedApproved.length <= 0}
            />
            <FileUpload
              children="Upload Logo"
              className="btn btn-primary me-2"
              onChange={onFileUpload}
              disabled={selectedApproved.length <= 0}
            />
          </div>
        </div>

        <EnterprisesTable
          enterprises={approved}
          selectRow={selectedApprovedConfig}
        />
        <h3 className="my-3">Rejected</h3>
        <EnterprisesTable enterprises={rejected} />
      </div>
      <Modal
        isOpen={isModalOpen}
        onDismiss={toggleModal}
        heading={modalProps.heading}
      >
        <p
          children={`Are you sure you want to ${modalProps?.action?.toLowerCase()} the following enterprises?`}
        />
        <ul>
          {selectedPending.map(id => {
            const enterprise = data.find(e => e.id === id);
            return <li key={id}>{enterprise.name}</li>;
          })}
        </ul>
        <ErrorMessage error={submitError} />
        <SubmitButtonSpinner
          children={modalProps.action}
          isSubmitting={isSubmitting}
          block
          onClick={modalProps.onSubmit}
        />
      </Modal>
    </Layout>
  );
};

export default EnterprisesPage;
