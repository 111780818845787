import { isISO8601, isDecimal, isUUID } from "validator";

const validate = values => {
  const requiredMsg = "This field is required";
  const errors = {};

  // Required fields
  if (!values.userID) errors.userID = requiredMsg;
  if (!values.firstName) errors.firstName = requiredMsg;
  if (!values.lastName) errors.lastName = requiredMsg;
  if (!values.fees && String(values.fees) !== "0") errors.fees = requiredMsg;
  if (!values.bankID) errors.bankID = requiredMsg;
  if (!values.fees && values.fees !== 0) errors.fees = requiredMsg;
  if (values.referredBy && !isUUID(values.referredBy))
    errors.referredBy = "Please enter a valid user ID";

  // Format errors
  if (!isISO8601(values.signUpDate))
    errors.signUpDate = "Invalid Format (use ISO 8601 format)";

  if (!isDecimal(String(values.fees), { decimal_digits: "1,2" }))
    errors.fees = "Fees must be a valid currency amount";

  if (values.fees < 0) errors.fees = "Fees can not be a negative value";
  if (values.fees > 5) errors.fees = "Fees can not be more than 5 dollars";
  return errors;
};

export default validate;
