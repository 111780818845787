import React from "react";
import ReferralStrategyForm from "./ReferralStrategyForm";
import { useNavigate } from "react-router-dom";
import { mutate } from "swr";
import ModalForm from "../ModalForm";

const parseSubmitValues = v => ({
  id: v.id,
  referralCode: v.referralCode,
  percentageAmount: Number(v.percentageAmount),
  maximumAmount: Number(v.maximumAmount),
  enabled: JSON.parse(v.enabled),
  fixedAmount: Number(v.fixedAmount),
  coin: "BTC"
});

const parseInitialValues = fetchedData =>
  [
    "id",
    "referralCode",
    "fixedAmount",
    "percentageAmount",
    "maximumAmount",
    "enabled"
  ].reduce((map, item) => {
    if (fetchedData[item]) map[item] = fetchedData[item];
    return map;
  }, {});

const ReferralStrategyModal = params => {
  const navigate = useNavigate();
  
  const onDismiss = () => {
    navigate("/referral");
  };

  const onError = (e, formActions) => {
    formActions.setErrors({ hidden: e });
    formActions.setSubmitting(false);
  };

  const onSuccess = async () => {
    mutate("/referralStrategy");
  };
  const getUrl = () => `/ReferralStrategy`;

  const getFetchUrl = id => `/ReferralStrategy/${id}`;
  return (
    <ModalForm
      parseSubmitValues={parseSubmitValues}
      parseInitialValues={parseInitialValues}
      resource="Referral Strategy"
      onSuccess={onSuccess}
      onError={onError}
      onDismiss={onDismiss}
      form={ReferralStrategyForm}
      getUrl={getUrl}
      getFetchUrl={getFetchUrl}
    />
  );
};

export default ReferralStrategyModal;
