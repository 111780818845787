import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import useSWR from "swr";
import useSelectedRows from "../hooks/useSelectedRows";
import Layout from "../components/Layout";
import Loader from "../components/Loader";
import ErrorMessage from "../components/ErrorMessage";
import UserTable from "../components/tables/UserTable";
import SearchFilter from "../components/SearchFilter";
import ResendRapidIDConfirmModal from "../components/modals/ResendRapidIDConfirmModal";
import SendEmailModal from "../components/modals/SendEmailModal";
import ConfirmUserDeleteModal from "../components/modals/ConfirmUserDeleteModal";
import { CSVLink } from "react-csv";
import { ToggleButton } from "../components/ToggleButton";

const defaultArray = [];

const UserPage = () => {
  const [users, setUsers] = useState([]);
  const { data, error } = useSWR("/user");
  const isLoading = !data && !error;
  const [selected, dispatch, selectRowConfig] = useSelectedRows([], {
    keyId: "userID"
  });
  const resetSelected = () => dispatch({ type: "RESET" });
  const [isEmailModalOpen, setEmailModalOpen] = useState(false);
  const [isRapidIDModalOpen, setRapidIDModalOpen] = useState(false);
  const [isUserDeleteModalOpen, setUserDeleteModalOpen] = useState(false);
  const [showUnverifiedUser, setShowUnverifiedUser] = useState(true);
  const [showVerifiedUser, setShowVerifiedUser] = useState(true);

  const to = data
    ? selected.map(userID => data.find(user => user.userID === userID))
    : [];
  const headers = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Email", key: "email" }
  ];

  useEffect(() => {
    let user = data;
    if (!showUnverifiedUser) {
      user = user.filter(user => user.idVerificationStatus === 3);
    }
    if (!showVerifiedUser) {
      user = user.filter(user => user.idVerificationStatus !== 3);
    }
    setUsers(user);
  }, [showUnverifiedUser, showVerifiedUser, data]);

  return (
    <Layout activeTab="Users">
      <div className="container-fluid py-5">
        <Loader loading={isLoading} />
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex flex-row">
            <div>
              <h1>Users</h1>
            </div>
            <div className="ms-5 d-flex justify-content-between align-items-start mt-2">
              <ToggleButton onChange={setShowVerifiedUser} />
              <span className="ms-3 mt-1">Verified User</span>
            </div>
            <div className="ms-5 d-flex justify-content-between align-items-start mt-2">
              <ToggleButton onChange={setShowUnverifiedUser} />
              <span className="ms-3 mt-1">Unverified User</span>
            </div>
          </div>
          <div>
            <Button
              type="button"
              onClick={() => setUserDeleteModalOpen(true)}
              disabled={!selected.length}
              children="Delete"
              className="me-2"
            />
            <Button
              type="button"
              onClick={() => setRapidIDModalOpen(true)}
              disabled={!selected.length}
              children="Resend Rapid ID"
              className="me-2"
            />
            <Button
              type="button"
              onClick={() => setEmailModalOpen(true)}
              disabled={!selected.length}
              children="Email"
            />
            <CSVLink
              data={data || defaultArray}
              headers={headers}
              filename={"User-list.csv"}
              className="btn btn-primary me-2 mx-2"
              target="_blank"
            >
              CSV Download
            </CSVLink>
          </div>
        </div>
        <SearchFilter
          data={data || defaultArray}
          setFiltered={setUsers}
          className="form-control my-4"
          placeholder="Search..."
        />
        <ErrorMessage error={error} />
        <UserTable
          users={users}
          selectRow={selectRowConfig}
          highlightUnverified
        />
        <ConfirmUserDeleteModal
          isOpen={isUserDeleteModalOpen}
          setOpen={setUserDeleteModalOpen}
          onDismiss={() => setUserDeleteModalOpen(false)}
          onSuccess={resetSelected}
          selectedIDs={selected}
          to={to}
        />
        <ResendRapidIDConfirmModal
          isOpen={isRapidIDModalOpen}
          setOpen={setRapidIDModalOpen}
          onDismiss={() => setRapidIDModalOpen(false)}
          onSuccess={resetSelected}
          selectedIDs={selected}
          to={to}
        />
        <SendEmailModal
          isOpen={isEmailModalOpen}
          onSuccess={resetSelected}
          onDismiss={() => setEmailModalOpen(false)}
          initialValues={{ to }}
        />
      </div>
    </Layout>
  );
};

export default UserPage;
