import React from "react";
import { Formik, Form, Field } from "formik";
import validate from "./validate";
import Loader from "../../Loader";
import Input from "../form-inputs/Input";
import ErrorMessage from "../../ErrorMessage";
import { Button } from "react-bootstrap";

const ReferralForm = ({ initialValues, onSubmit }) => {
  return (
    <Formik
      initialValues={{
        fixedAmount: 0,
        percentageAmount: 0,
        enabled: false,
        ...initialValues.selectReferral
      }}
      validate={validate}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isSubmitting, errors }) => (
        <Form style={{ flex: "1" }}>
          <Input name="fixedAmount" label="Fixed Amount" />
          <Input name="percentageAmount" label="Percentage Amount" />
          <label>Enabled</label>
          <Field
            as="select"
            name="enabled"
            id="enabled"
            className="form-control"
          >
            <option value="true">True</option>
            <option value="false">False</option>
          </Field>
          <p></p>
          <ErrorMessage error={errors.hidden} />
          <Button
            className="btn btn-primary btn-block relative d-flex justify-content-center"
            type="submit"
            disabled={isSubmitting}
          >
            <Loader
              loading={isSubmitting}
              noBackground
              noStretch
              light
              diameter="1.4rem"
            />
            <span className="mx-2">Submit</span>
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default ReferralForm;
