import React from "react";
import { Formik, Form } from "formik";
import validate from "./validate";
import Loader from "../../Loader";
import Input from "../form-inputs/Input";
import TextArea from "../form-inputs/TextArea";
import ErrorMessage from "../../ErrorMessage";

const _initialValues = {
  to: [],
  cc: "",
  bcc: "",
  subject: "",
  body: ""
};

const EmailForm = ({ initialValues, onSubmit }) => {
  initialValues = { ..._initialValues, ...initialValues };
  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isSubmitting, errors }) => (
        <Form style={{ flex: "1" }}>
          <label>To:</label>
          <p>{initialValues.to.map(v => v.email).join("; ")}</p>
          <Input name="cc" label="CC" placeholder="email@email.com; ..." />
          <Input name="bcc" label="BCC" placeholder="email@email.com; ..." />
          <Input name="subject" label="Subject" />
          <TextArea name="body" label="Body" rows="10" />
          <ErrorMessage error={errors.hidden} />
          <button
            className="btn btn-primary btn-block relative d-flex justify-content-center"
            type="submit"
            disabled={isSubmitting}
          >
            <Loader
              loading={isSubmitting}
              noBackground
              noStretch
              light
              diameter="1.4rem"
            />
            <span className="mx-2">Send</span>
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default EmailForm;
