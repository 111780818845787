import React, { useState } from "react";
import Modal from "../Modal";
import Table from "../tables/Table";
import ErrorMessage from "../ErrorMessage";
import SubmitSpinnerButton from "../forms/SubmitSpinnerButton";
import gpib from "../../apis/gpib";
import { mutate } from "swr";

const defaultArray = [];

const columnConfig = {
  userID: {},
  name: {}
};

const parseData = data =>
  data.map(({ userID, firstName, lastName }) => ({
    userID,
    name: [firstName, lastName].filter(v => v).join(" ")
  }));

const ConfirmUserDeleteModal = ({
  isOpen,
  onDismiss,
  onSuccess,
  selectedIDs,
  to = defaultArray,
  ...props
}) => {
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const deleteUsers = async (e, { onDismiss }) => {
    try {
      setError(null);
      setLoading(true);
      await gpib.secure.delete("/user", {
        data: selectedIDs
      });
      setLoading(false);
      await onDismiss();
      if (onSuccess) onSuccess();
      mutate("/user", state =>
        state.filter(u => !selectedIDs.find(id => u.userID === id))
      );
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  };
  return (
    <Modal
      heading="Delete users?"
      large
      isOpen={isOpen}
      onDismiss={onDismiss}
      {...props}
    >
      {({ wrapCallback }) => (
        <>
          <Table
            columnConfig={columnConfig}
            data={parseData(to)}
            pagination={false}
            headerStyle={{ display: "none" }}
            keyField="userID"
          />
          <ErrorMessage error={error} className="mt-3" />
          <SubmitSpinnerButton
            children="Delete"
            onClick={wrapCallback(deleteUsers)}
            className="mt-3"
            isSubmitting={isLoading}
          />
        </>
      )}
    </Modal>
  );
};

export default ConfirmUserDeleteModal;
