import React from "react";
import { mutate } from "swr";
import DepositHintsForm from "./DepositHintsForm";
import ModalForm from "../ModalForm";
import { useNavigate } from "react-router-dom";

const parseSubmitValues = v => ({
  id: v.id,
  userID: v.userID,
  employerName: v.employerName,
  depositReference: v.depositReference,
  bankStatement: v.bankStatement,
  depositAmount: Number(v.depositAmount)
});

const parseInitialValues = fetchedData =>
  [
    "id",
    "userID",
    "employerName",
    "depositAmount",
    "depositReference",
    "bankStatement"
  ].reduce((map, item) => {
    if (fetchedData[item]) map[item] = fetchedData[item];
    return map;
  }, {});

const DepositHintsModalForm = props => {
  const navigate = useNavigate();

  const onDismiss = location => {
    const path = location.pathname.replace(/\/deposithints\/.*$/, "");
    navigate(path);
  };

  const onSuccess = (id, v) => {
    if (id) mutate(`/user/${id}/deposithints`, ac => ({ ...ac, ...v }));
  };

  const onError = (e, formActions) => {
    formActions.setErrors({ hidden: e });
    formActions.setSubmitting(false);
  };

  const getUrl = id => `/user/${id}/deposithints`;

  const getFetchUrl = id => `/user/${id}/deposithints`;

  const getHeading = id =>
    id ? "Edit Payroll Information" : "Add Payroll Information";

  return (
    <ModalForm
      resource="DepositHints"
      onDismiss={onDismiss}
      form={DepositHintsForm}
      getUrl={getUrl}
      getFetchUrl={getFetchUrl}
      getHeading={getHeading}
      onSuccess={onSuccess}
      onError={onError}
      parseInitialValues={parseInitialValues}
      parseSubmitValues={parseSubmitValues}
    />
  );
};

export default DepositHintsModalForm;
