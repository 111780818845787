import React from "react";
import { Formik, Form } from "formik";
import moment from "moment";
import Input from "../form-inputs/Input";
import ErrorMessage from "../../ErrorMessage";
import validate from "./validate.js";
import SubmitSpinnerButton from "../SubmitSpinnerButton";

const AccountInfoesForm = ({
  initialValues: _inititalValues,
  onSubmit,
  submitText = "Submit",
  isEditForm
}) => {
  // Define default values and override with any passed in from props
  const now = moment().seconds(0).milliseconds(0).format("Y-MM-DDTkk:mm:ss");
  const initialValues = {
    userID: "",
    firstName: "",
    middleName: "",
    lastName: "",
    signUpDate: now,
    randomCent: Math.floor(Math.random() * 99) + 1,
    idVerificationStatus: 0,
    idVerificationUrl: "",
    twoFactorSecret: "",
    fees: 1,
    yob: 0,
    bankID: 0,
    referredBy: "",
    ..._inititalValues
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      enableReinitialize
    >
      {({ isSubmitting, errors }) => (
        <Form className="deposit-form">
          <Input
            label="User ID"
            name="userID"
            disabled={initialValues?.userID}
          />
          <Input label="First Name" name="firstName" />
          <Input label="Middle Name" name="middleName" />
          <Input label="Last Name" name="lastName" />
          <Input label="Sign Up Date" name="signUpDate" />
          <Input label="Random Cent" name="randomCent" />
          <Input label="ID Verification Status" name="idVerificationStatus" />
          <Input label="YOB" name="yob" />
          <Input label="Bank ID" name="bankID" />
          <Input label="Fees" name="fees" />
          <Input
            label="Referred by"
            name="referredBy"
            disabled={initialValues?.referredBy}
          />
          <ErrorMessage error={errors.hidden} />
          <SubmitSpinnerButton
            submitText={submitText}
            isSubmitting={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AccountInfoesForm;
