import React from "react";
import Modal from "../../Modal";
import { useParams, useNavigate } from "react-router-dom";
import RebuildTransferForm from "./RebuildDepositForm";

const RebuildDepositFormModal = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const onDismiss = () => navigate("/deposits");

  return (
    <Modal isOpen onDismiss={onDismiss} heading="Rebuild deposit">
      {({ onDismiss }) => (
        <>
          <RebuildTransferForm
            onSuccess={onDismiss}
            initialValues={{ selected: id }}
          />
        </>
      )}
    </Modal>
  );
};
export default RebuildDepositFormModal;
