import React from "react";
import axios from "axios";
import useSWR from "swr";
import Modal from "../../Modal";
import qs from "qs";
import { useParams, useLocation, useNavigate } from "react-router";
import PendingTransferForm from "./PendingTransferForm";
import Loader from "../../Loader";

const PendingTransferModalForm = () => {
  const navigate = useNavigate();
  const { selected } = useParams();
  const parsedSelected = selected.split(",");
  const { search } = useLocation();
  const { total } = search && qs.parse(search, { ignoreQueryPrefix: true });

  // get amount from total like "0.00366101 BTC" as a number using regex
  const amount = total && Number(total.match(/[\d.]+/)[0]);

  const { data, error } = useSWR(
    "https://api.blockcypher.com/v1/btc/main",
    url => axios.get(url).then(res => res.data)
  );
  const isFetching = !data && !error;
  const fee = Math.round((data?.medium_fee_per_kb || 0) / 1000);

  const onDismiss = () => navigate("/pendingtransfers");

  return (
    <Modal isOpen onDismiss={onDismiss} heading="Process transactions">
      {({ onDismiss }) => (
        <>
          <p>
            <span>Selected IDs ({parsedSelected.length}):</span>
            <span className="ms-2">{parsedSelected.join(", ")}</span>
          </p>
          {total && (
            <p>
              <span children="Totalling: " />
              <span children={total} />
            </p>
          )}
          <Loader loading={isFetching} />
          <PendingTransferForm
            initialValues={{ selected: parsedSelected, fee, amount: amount }}
            onSuccess={onDismiss}
          />
        </>
      )}
    </Modal>
  );
};

export default PendingTransferModalForm;
