import React from "react";
import EnterprisesForm from "./EnterprisesForm";
import ModalForm from "../ModalForm";
import { useNavigate } from "react-router-dom";
import { mutate } from "swr";

const parseSubmitValues = v => ({
  id: v.id,
  name: v.name,
  abn: String(v.abn).trim().replace(/\s/gi, ""),
  emailDomain: v.emailDomain,
  contactEmail: v.contactEmail,
  contactPhoneNumber: v.contactPhoneNumber,
  payrollContact: v.payrollContact,
  payrollInformation: v.payrollInformation,
  numberOfEmployees: Number(v.numberOfEmployees),
  nameAbbreviation: v.nameAbbreviation,
  notes: v.notes,
  status: v.status,
  logoURL: v.logoURL
});

const parseInitialValues = fetchedData =>
  [
    "id",
    "name",
    "abn",
    "emailDomain",
    "contactEmail",
    "contactPhoneNumber",
    "payrollContact",
    "payrollInformation",
    "numberOfEmployees",
    "notes",
    "nameAbbreviation",
    "status",
    "logoURL"
  ].reduce((map, item) => {
    if (fetchedData[item]) map[item] = fetchedData[item];
    return map;
  }, {});

const EnterpriseModalEdit = params => {
  const navigate = useNavigate();
  
  const onDismiss = () => {
    navigate("/enterprises");
  };

  const onError = (e, formActions) => {
    formActions.setErrors({ hidden: e });
    formActions.setSubmitting(false);
  };

  const onSuccess = async () => {
    mutate("/enterprise");
  };
  const getUrl = () => `/Enterprise`;

  const getFetchUrl = id => `/Enterprise/${id}`;
  return (
    <ModalForm
      parseSubmitValues={parseSubmitValues}
      parseInitialValues={parseInitialValues}
      resource="Enterprises"
      onSuccess={onSuccess}
      onError={onError}
      onDismiss={onDismiss}
      form={EnterprisesForm}
      getUrl={getUrl}
      getFetchUrl={getFetchUrl}
    />
  );
};

export default EnterpriseModalEdit;
